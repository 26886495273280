import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      loading:true,
      dialog_from_input:true,
      dialog_form: false,
      dialog_delete:false,
    }
  },
  methods: {
    ...mapActions({
      setSnakbar: 'snakbar/setSnakbar',
      setData: 'data/setData',
      setEditData: 'setDataEdit'
    }),
    formatAsCurrency (value, dec) {
        dec = dec || 0
        if (value === null) {
            return null
        }
        return '' + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    },
    async get_data_master (table,store) {
      await this.axios.get(`/v1/${table}/all?company_id=` + this.user.company_id)
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: `${store}` })
        })
        .catch(err => console.log(err))
    },
    async go (table,store,params = '') {
      await this.axios.get('/v1/'+ table +'/all?company_id=' + this.user.company_id + '&' + params, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
    async edit (item) {
      this.user_edit = item
      await this.setEditData(item)
      await this.add()
    },
    deleteItem (item) {
      this.data_delete = item
      this.dialog_delete = true
    },

    add () {
      this.dialog_form = true
    },
    close () {
      this.dialog_delete = false
      this.setEditData(false)
      this.dialog_form = false
      this.dialog_form = false
    },
    eventChange (event) {
      const files = event.target.files
      if (files[0].size > 300 * 1024) {
        this.setSnakbar({
            status: true,
            pesan: 'Image size to large...',
            color_snakbar: 'red'
          })
      } else {
        this.foto = files[0]
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.imgurl = fileReader.result
        })
        fileReader.readAsDataURL(this.foto)
      }

    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
  },
  computed:{
    // backgroundColor () {
    //   return `background-color:${this.color} !important`
    // },
    ...mapGetters({
      user:'auth/user',
      color: 'layout/color',
      edit_data: 'data_edit'
    })
  },

}
