<template>
  <div>
    <table slot="pdf-content" class="pdf-report" width="100%">
        <thead>
          <tr>
            <th :colspan="headers.length">{{title}}</th>
          </tr>
          <tr>
            <th v-for="item in headers" :key="item">{{item}}</th>
          </tr>
        </thead>
        <tbody>
         <slot></slot>
        </tbody>
    </table>
  </div>
</template>
<style lang="css" scoped>
  table {
      border-collapse: collapse;
      width: 100% !important;
      margin-top: 60px;
      padding-left: 20px;
      padding-right: 20px;

  }
    .pdf-report table, .pdf-report th, .pdf-report td{
     padding: 5px;
  }
  /* .table tr td, .table tr th {
      padding: 10px;
      text-align: left;
  }
    .table tr th {
        background-color:#f5f5f5;
        color: black;
    } */
</style>
<script>
export default {
  props:['headers','title']

}
</script>
