import {mapGetters} from 'vuex'
export default {
  props: [ 'data'],
  components:{
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog:true,
    items:[],
    progress:true,
  }),

  computed: {
    ...mapGetters({
      color: 'layout/color',
    }),
    backgroundColor () {
      return `background-color:${this.color} !important`
    },
  },
}
