import Progress from '../../../components/external/Progress'
import { mapActions, mapGetters } from 'vuex'
import formInput from './components/form'
import dialogDelete from '../../../components/external/DialogDelete'
import dialogDetail from './components/detail'
import GlobalMixin from '../../mixin/index'
export default {
  mixins:[GlobalMixin],
  data () {
    return {
      user_edit: null,
      dialog_delete:false,
      dialog_detail:false,
      data_detail:{},
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      branch_id:'',
      from_menu:false,
      to_menu:false,
      params:'',
      branch_id:'',
      headers: [


        {
          text: 'Date',
          value: 'doc_date'
        },
        {
          text: 'Document',
          align: 'start',
          value: 'doc_no'
        },
        {
          text: 'From Branch',
          align: 'start',
          value: 'from_branch.name'
        },
        {
          text: 'To Branch',
          align: 'start',
          value: 'to_branch.name'
        },
        {
          text: 'Note',
          value: 'note'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'action'
        }

      ]
    }
  },
  computed: {
  },
  components: {
    Progress,
    formInput,
    dialogDelete,
    "DetailDialog" : dialogDetail
  },
  methods: {
    ...mapActions({
      setEditData:'cart/setEditData',
      setCart:'cart/setCart'
    }),

    openDetail: function (item = {}) {
      this.data_detail = item
      this.dialog_detail = !this.dialog_detail
    },
    async go (table,store,param = '') {
      await this.axios.get('/v1/'+ table +'?company_id=' + this.user.company_id + `&${param}`, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => {
          if (err.response.data.message === "empty!") {
            this.setData({ data: [], table_name: store })
          }
        })
      this.loading = false
    },
    async deleteAction() {
      const data = {
        is_deleted: 'Y',
        update_by: this.user.fullname,
        id: this.data_delete.id,
      }
      await this.axios.put('v1/inventory/move/delete/' + this.data_delete.id,JSON.stringify(data),{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success delete data',
            color_snakbar: 'success'
          })
          await this.go('inventory/move','inventory_move')
          this.close()
        })
        .catch((err) => console.log(err.response))
    },
    close () {
      this.setEditData(null)
      this.user_edit = null
      this.dialog_delete = false
      this.dialog_form = false
    },
  },
}

