<template>
  <v-dialog
  v-model="dialog"
  max-width="340"
   @click:outside="close()"
      @keydown.esc="close()"
  >
  <v-card>
      <v-card-title class="headline">Do you want to delete this data ?</v-card-title>
      <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn

          @click="close"
      >
          No
      </v-btn>

      <v-btn
          @click="deleteAction"
      >
          Yes
      </v-btn>
      </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>

export default {
  props:['dialog','data'],
  methods:{
    close() {
      this.$emit('close')
    },
    deleteAction() {
      this.$emit('deleteAction')
    }
  }
}
</script>
